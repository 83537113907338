export const supportedLangs = [
	"en",
	"fr",
	"pt",
	"es",
	"de"
];

export const bgNames = [
	'',
	'aranami',
	'shinji_w',
	'justin brown',
	'dick thomas johnson',
	'john tregoning', // 5
	'miki yoshihito',
	'lwyang',
	'andrew hitchcock',
	'jim g',
	'gavid goehring', // 10
	'ralph daily',
	'missbossy',
	'garry knight',
];

export const bgURLs = [
	'',
	'https://www.flickr.com/photos/z0/7904559194/',
	'',
	'https://www.flickr.com/photos/justininsd/7618567732/',
	'https://www.flickr.com/photos/31029865@N06/6399693885/',
	'https://www.flickr.com/photos/johnniewalker/12624055345/', // 5
	'https://www.flickr.com/photos/mujitra/14830012507',
	'',
	'https://www.flickr.com/photos/adpowers/21602218/',
	'https://www.flickr.com/photos/jimg944/258006177/',
	'https://www.flickr.com/photos/carbonnyc/2627326773/', //10
	'https://www.flickr.com/photos/ralphandjenny/4163977166/',
	'https://www.flickr.com/photos/missbossy/2726629055',
	'https://www.flickr.com/photos/garryknight/6240786602/',
];

export const popularNames = [
	'james',
	'jim',
	'john',
	'robert',
	'rob',
	'michael',
	'mike',
	'william',
	'will',
	'david',
	'dave',
	'richard',
	'rich',
	'joseph',
	'charles',
	'charlie',
	'thomas',
	'tom',
	'christopher',
	'chris',
	'daniel',
	'dan',
	'matthew',
	'matt',
	'donald',
	'don',
	'anthony',
	'tony',
	'paul',
	'mark',
	'george',
	'steven',
	'steve',
	'kenneth',
	'ken',
	'andrew',
	'andy',
	'edward',
	'ed',
	'joshua',
	'josh',
	'brian',
	'kevin',
	'ronald',
	'ron',
	'timothy',
	'tim',
	'jason',
	'jeffrey',
	'jeff',
	'gary',
	'ryan',
	'nicholas',
	'nick',
	'eric',
	'jacob',
	'jake',
	'stephen',
	'jonathan',
	'jon',
	'larry',
	'frank',
	'scott',
	'justin',
	'brandon',
	'raymond',
	'ray',
	'gregory',
	'greg',
	'samuel',
	'sam',
	'benjamin',
	'ben',
	'patrick',
	'pat',
	'jack',
	'dennis',
	'jerry',
	'alexander',
	'alex',
	'tyler',
	'henry',
	'douglas',
	'doug',
	'peter',
	'pete',
	'aaron',
	'walter',
	'jose',
	'adam',
	'zachary',
	'zach',
	'harold',
	'nathan',
	'nate',
	'kyle',
	'carl',
	'arthur',
	'gerald',
	'roger',
	'lawrence',
	'keith',
	'albert',
	'al',
	'jeremy',
	'terry',
	'joe',
	'sean',
	'willie',
	'jesse',
	'austin',
	'christian',
	'ralph',
	'billy',
	'bill',
	'bruce',
	'bryan',
	'roy',
	'eugene',
	'ethan',
	'louis',
	'wayne',
	'jordan',
	'harry',
	'russell',
	'alan',
	'juan',
	'philip',
	'phill',
	'randy',
	'dylan',
	'howard',
	'vincent',
	'bobby',
	'bob',
	'johnny',
	'phillip',
	'shawn',
	'mary',
	'patricia',
	'jennifer',
	'jenny',
	'elizabeth',
	'liz',
	'linda',
	'barbara',
	'barb',
	'susan',
	'margaret',
	'marge',
	'maggie',
	'jessica',
	'jess',
	'dorothy',
	'sarah',
	'karen',
	'nancy',
	'betty',
	'lisa',
	'sandra',
	'helen',
	'ashley',
	'donna',
	'kimberly',
	'kim',
	'carol',
	'michelle',
	'emily',
	'amanda',
	'melissa',
	'deborah',
	'laura',
	'stephanie',
	'steph',
	'rebecca',
	'becky',
	'bec',
	'sharon',
	'cynthia',
	'kathleen',
	'ruth',
	'anna',
	'shirley',
	'amy',
	'angela',
	'virginia',
	'brenda',
	'pamela',
	'catherine',
	'katherine',
	'katie',
	'nicole',
	'christine',
	'janet',
	'debra',
	'samantha',
	'carolyn',
	'rachel',
	'heather',
	'maria',
	'diane',
	'frances',
	'joyce',
	'julie',
	'emma',
	'evelyn',
	'martha',
	'joan',
	'kelly',
	'christina',
	'lauren',
	'judith',
	'alice',
	'victoria',
	'doris',
	'ann',
	'jean',
	'cheryl',
	'marie',
	'megan',
	'kathryn',
	'andrea',
	'jacqueline',
	'gloria',
	'teresa',
	'janice',
	'sara',
	'rose',
	'hannah',
	'julia',
	'theresa',
	'judy',
	'grace',
	'beverly',
	'denise',
	'marilyn',
	'mildred',
	'amber',
	'danielle',
	'brittany',
	'olivia',
	'diana',
	'jane',
	'lori',
	'madison',
	'tiffany',
	'kathy',
	'tammy',
	'crystal',
];

export const drinkType : ("I" | "G" | "A" | "T" | "R" | "S" | "L" | "E" | "O" | "P" | "F" | "H" | "W")[] = [
	'I',
	'G',
	'H',
	'I',
	'H',
	'I',
	'W',
	'I',
	'H',
	'H',
	'H',
	'H',
	'F',
	'H',
	'H',
	'W',
	'H',
	'F',
	'I',
	'W',
	'H',
	'I',
	'I',
	'H',
	'H',
	'W',
	'H',
	'H',
	'H',
	'W',
	'F',
	'I',
	'I',
	'I',
	'I',
	'H',
	'H',
	'H',
	'I',
	'H',
	'H',
	'H',
	'I',
	'I',
	'I',
	'H',
	'I',
	'I',
	'H',
	'G',
	'I',
	'F',
	'G',
	'I',
	'F',
	'G',
	'H',
	'H',
	'F',
	'H',
	'F',
	'I',
	'I',
	'H',
	'H',
	'I',
	'I',
	'H',
	'H',
	'H',
	'H',
	'H',
	'H',
	'W',
	'H',
	'H',
	'H',
	'H',
	'I',
	'I',
	'I',
	'P',
	'F',
	'H',
	'H',
	'I',
	'I',
	'I',
	'I',
	'I',
	'W',
	'H',
	'I',
	'G',
	'H',
	'I',
	'I',
	'I',
	'H',
	'H',
	'I',
	'I',
	'H',
	'H',
	'W',
	'I',
	'H',
	'H',
	'I',
	'H',
	'I',
	'I',
	'I',
	'H',
	'I',
	'H',
	'P',
	'H',
	'I',
	'H',
	'I',
	'F',
	'H',
	'I',
	'F',
	'H',
	'H',
	'F',
	'I',
	'I',
	'H',
	'H',
	'H',
	'H',
	'H',
	'W',
	'F',
	'I',
	'I',
	'I',
	'H',
	'I',
	'H',
	'F',
	'I',
	'H',
	'H',
	'H',
	'I',
	'F',
	'H',
	'H',
	'I',
	'H',
	'H',
	'H',
	'H',
	'H',
	'H',
	'H',
	'H',
	'H',
	'H',
	'H',
	'I',
	'F',
	'H',
	'I',
	'I',
	'I',
	'H',
	'I',
	'H',
	'G',
	'I',
	'I',
	'G',
	'H',
	'H',
	'G',
	'I',
	'I',
	'I',
	'H',
	'H',
	'H',
	'I',
	'H',
	'I',
	'H',
	'I',
	'H',
	'I',
	'H',
	'H',
	'H',
	'I',
	'H',
	'G',
	'I',
	'F',
	'H',
	'H',
	'I',
	'H',
	'H',
	'F',
	'H',
	'I',
	'H',
	'I',
	'H',
	'I',
	'I',
	'I',
	'I',
	'I',
	'I',
	'H',
	'I',
	'H',
	'F',
	'H',
	'I',
	'H',
	'I',
	'I',
	'I',
	'I',
	'H',
	'I',
	'I',
	'I',
	'F',
	'I',
	'P',
	'L',
	'S',
	'F',
	'I',
	'I',
	'I',
	'I',
	'I',
	'I',
	'H',
	'I',
	'P',
	'I',
	'I',
	'G',
	'I',
	'G',
	'I',
	'F',
	'F',
	'F',
	'H',
	'H',
	'I',
	'I',
	'I',
	'F',
	'I',
	'P',
	'H',
	'H',
	'H',
	'I',
	'H',
	'H',
	'H',
	'G',
	'G',
	'H',
	'H',
	'F',
	'I',
	'P',
	'F',
	'F',
	'P',
	'I',
	'I',
	'F',
	'H',
	'H',
	'H',
	'H',
	'H',
	'H',
	'I',
	'F',
	'P',
	'F',
	'H',
	'H',
	'I',
	'I',
	'I',
	'F',
	'I',
	'I',
	'I',
	'I',
	'H',
	'H',
	'I',
	'H',
	'H',
	'H',
	'H',
	'P',
	'H',
	'T',
	'H',
	'H',
	'F',
	'H',
	'H',
	'P',
	'T',
	'T',
	'P',
	'I',
	'H',
	'H',
	'I',
	'P',
	'F',
	'H',
	'I',
	'F',
	'I',
	'H',
	'H',
	'F',
	'I',
	'H',
	'G',
	'S',
	'H',
	'H',
	'I',
	'I',
	'F',
	'H',
	'P',
	'H',
	'H',
	'H',
	'I',
	'H',
	'H',
	'H',
	'P',
	'F',
	'I',
	'P',
	'H',
	'F',
	'F',
	'H',
	'G',
	'H',
	'H',
	'I',
	'H',
	'I',
	'H',
	'H',
	'F',
	'H',
	'H',
	'H',
	'F',
	'H',
	'I',
	'P',
	'F',
	'F',
	'I',
	'H',
	'H',
	'H',
	'H',
	'F',
	'P',
	'H',
	'H',
	'F',
	'H',
	'H',
	'H',
	'H',
	'H',
	'H',
	'H',
	'I',
	'I',
	'P',
	'I',
	'I',
	'H',
	'I',
	'I',
	'I',
	'F',
	'I',
	'H',
	'H',
	'H',
	'H',
	'I',
	'H',
	'O',
	'H',
	'H',
	'H',
	'H',
	'H',
	'H',
	'G',
	'H',
	'I',
	'H',
	'H',
	'H',
	'F',
	'H',
	'H',
	'F',
	'F',
	'I',
	'F',
	'H',
	'H',
	'G',
	'H',
	'F',
	'H',
	'H',
	'H',
	'F',
	'H',
	'H',
	'H',
	'H',
	'I',
	'H',
	'H',
	'H',
	'T',
	'P',
	'H',
	'H',
	'H',
	'I',
	'H',
	'H',
	'H',
	'H',
	'H',
	'F',
	'F',
	'I',
	'F',
	'H',
	'H',
	'P',
	'H',
	'H',
	'H',
	'F',
	'I',
	'I',
	'H',
	'H',
	'I',
	'F',
	'F',
	'G',
	'I',
	'H',
	'I',
	'H',
	'H',
	'H',
	'H',
	'I',
	'H',
	'H',
	'H',
	'I',
	'H',
	'H',
	'H',
	'H',
	'F',
	'E',
	'P',
	'P',
	'F',
	'H',
	'H',
	'H',
	'H',
	'H',
	'H',
	'H',
	'H',
	'H',
	'I',
	'H',
	'P',
	'F',
	'R',
	'H',
	'F',
	'W',
	'I',
	'I',
	'H',
	'H',
	'H',
	'H',
	'I',
	'H',
	'I',
	'I',
	'H',
	'H',
	'H',
	'H',
	'I',
	'F',
	'F',
	'I',
	'F',
	'F',
	'H',
	'I',
	'S',
	'H',
	'H',
	'H',
	'H',
	'I',
	'H',
	'H',
	'G',
	'H',
	'H',
	'H',
	'I',
	'H',
	'H',
	'H',
	'I',
	'H',
	'H',
	'H',
	'H',
	'I',
	'H',
	'F',
	'F',
	'F',
	'H',
	'H',
	'F',
	'H',
	'H',
	'H',
	'H',
	'S',
	'I',
	'F',
	'H',
	'H',
	'F',
	'H',
	'P',
	'H',
	'I',
	'I',
	'H',
	'F',
	'H',
	'H',
	'L',
	'F',
	'H',
	'H',
	'H',
	'F',
	'H',
	'I',
	'F',
	'I',
	'H',
	'F',
	'I',
	'H',
	'P',
	'F',
	'H',
	'F',
	'I',
	'H',
	'H',
	'H',
	'F',
	'I',
	'I',
	'I',
	'H',
	'P',
	'H',
	'H',
	'H',
	'F',
	'F',
	'F',
	'H',
	'H',
	'H',
	'H',
	'H',
	'F',
	'E',
	'H',
	'F',
	'H',
	'I',
	'H',
	'R',
	'H',
	'H',
	'F',
	'I',
	'F',
	'H',
	'F',
	'I',
	'H',
	'H',
	'H',
	'F',
	'H',
	'F',
	'H',
	'H',
	'F',
	'H',
	'H',
	'I',
	'H',
	'F',
	'T',
	'I',
	'I',
	'I',
	'H',
	'F',
	'F',
	'H',
	'L',
	'H',
	'I',
	'F',
	'H',
	'H',
	'H',
	'H',
	'I',
	'P',
	'H',
	'G',
	'H',
	'G',
	'H',
	'H',
	'I',
	'I',
	'I',
	'F',
	'H',
	'P',
	'I',
	'F',
	'F',
	'I',
	'H',
	'H',
	'P',
	'I',
	'H',
	'F',
	'H',
	'F',
	'H',
	'P',
	'H',
	'H',
	'H',
	'I',
	'H',
	'F',
	'F',
	'F',
	'H',
	'G',
	'H',
	'H',
	'H',
	'H',
	'H',
	'H',
	'F',
	'H',
	'I',
	'F',
	'I',
	'H',
	'H',
	'I',
	'H',
	'F',
	'H',
	'F',
	'P',
	'I',
	'F',
	'P',
	'F',
	'F',
	'I',
	'H',
	'H',
	'F',
	'I',
	'T',
	'I',
	'H',
	'H',
	'T',
	'H',
	'H',
	'F',
	'I',
	'H',
	'H',
	'H',
	'H',
	'H',
	'H',
	'I',
	'H',
	'F',
	'H',
	'H',
	'I',
	'H',
	'H',
	'H',
	'H',
	'H',
	'H',
	'F',
	'F',
	'I',
	'I',
	'H',
	'H',
	'I',
	'I',
	'H',
	'F',
	'I',
	'H',
	'H',
	'H',
	'H',
	'L',
	'H',
	'I',
	'H',
	'P',
	'I',
	'H',
	'H',
	'F',
	'I',
	'F',
	'H',
	'H',
	'H',
	'H',
	'H',
	'H',
	'H',
	'H',
	'H',
	'H',
	'H',
	'H',
	'H',
	'H',
	'H',
	'H',
	'F',
	'F',
	'F',
	'H',
	'G',
	'H',
	'F',
	'F',
	'H',
	'F',
	'H',
	'H',
	'H',
	'I',
	'H',
	'H',
	'H',
	'H',
	'H',
	'H',
	'F',
	'H',
	'I',
	'H',
	'I',
	'F',
	'H',
	'H',
	'H',
	'H',
	'H',
	'H',
	'F',
	'H',
	'T',
	'H',
	'H',
	'H',
	'H',
	'H',
	'H',
	'H',
	'I',
	'H',
	'H',
	'H',
	'H',
	'I',
	'H',
	'I',
	'I',
	'H',
	'H',
	'H',
	'H',
	'H',
	'I',
	'H',
	'H',
	'H',
	'H',
	'H',
	'W',
	'H',
	'H',
	'H',
	'I',
	'I',
	'H',
	'H',
	'H',
	'I',
	'H',
	'F',
	'I',
	'I',
	'F',
	'H',
	'H',
	'H',
	'I',
	'I',
	'F',
	'H',
	'I',
	'F',
	'G',
	'R',
	'F',
	'H',
	'F',
	'I',
	'H',
	'F',
	'H',
	'H',
	'H',
	'H',
	'F',
	'G',
	'I',
	'H',
	'F',
	'I',
	'H',
	'H',
	'F',
	'S',
	'F',
	'H',
	'F',
	'F',
	'S',
	'F',
	'I',
	'I',
	'I',
	'I',
	'I',
	'H',
	'H',
	'H',
	'G',
	'A',
	'F',
	'A',
	'H',
	'I',
	'H',
	'H',
	'T',
	'I',
	'I',
	'H',
	'I',
	'H',
	'I',
	'P',
	'G',
	'F',
	'H',
	'I',
	'A',
	'H',
	'I',
	'I',
	'I',
	'H',
	'F',
	'H',
	'H',
	'H',
	'I',
	'G',
	'P',
	'H',
	'H',
	'I',
	'I',
	'I',
	'H',
	'H',
	'H',
	'I',
	'F',
	'F',
	'H',
	'I',
	'H',
	'H',
	'T',
	'I',
	'I',
	'I',
	'H',
	'H',
	'I',
	'I',
	'I',
	'H',
	'I',
	'H',
	'F',
	'I',
	'I',
	'G',
	'H',
	'H',
	'I',
	'I',
	'H',
	'I',
	'H',
	'I',
	'H',
	'H',
	'L',
	'T',
	'H',
	'H',
	'H',
	'H',
	'G',
	'I',
	'H',
	'H',
	'I',
	'H',
	'H',
	'I',
	'H',
	'H',
	'H',
	'I',
	'H',
	'I',
	'I',
	'H',
	'H',
	'I',
	'H',
	'W',
	'F',
	'H',
	'I',
	'H',
	'F',
	'H',
	'H',
	'P',
	'F',
	'H',
	'H',
	'H',
	'H',
	'H',
	'H',
	'I',
	'F',
	'I',
	'H',
	'H',
	'H',
	'H',
	'F',
	'H',
	'H',
	'H',
	'H',
	'H',
	'G',
	'F',
	'T',
	'I',
	'H',
	'F',
	'H',
	'H',
	'H',
	'L',
	'H',
	'F',
	'H',
	'I',
	'H',
	'R',
	'I',
	'I',
	'H',
	'H',
	'H',
	'I',
	'H',
	'I',
	'I',
	'I',
	'H',
	'I',
	'F',
	'H',
	'I',
	'I',
	'I',
	'H',
	'I',
	'I',
	'I',
	'H',
	'I',
	'H',
	'H',
	'I',
	'F',
	'H',
	'I',
	'I',
	'H',
	'H',
	'R',
	'I',
	'F',
	'H',
	'P',
	'H',
	'H',
	'F',
	'I',
	'I',
	'I',
	'P',
	'I',
	'T',
	'I',
	'R',
	'T',
	'H',
	'H',
	'T',
	'I',
	'I',
	'T',
	'A',
	'I',
	'H',
	'I',
	'T',
	'H',
	'I',
	'F',
	'F',
	'F',
	'F',
	'H',
	'H',
	'I',
	'P',
	'P',
	'I',
	'I',
	'H',
	'I',
	'H',
	'I',
	'F',
	'F',
	'H',
	'I',
	'H',
	'H',
	'I',
	'H',
	'H',
	'I',
	'H',
	'H',
	'F',
	'I',
	'I',
	'H',
	'I',
	'H',
	'H',
	'F',
	'F',
	'H',
	'I',
	'H',
	'S',
	'H',
	'H',
	'I',
	'H',
	'I',
	'I',
	'H',
	'I',
	'H',
	'I',
	'P',
	'O',
	'I',
	'I',
	'F',
	'H',
	'I',
	'T',
	'H',
	'H',
	'P',
	'I',
	'H',
	'P',
	'H',
	'I',
	'I',
	'H',
	'H',
	'H',
	'I',
	'I',
	'H',
	'I',
	'I',
	'H',
	'I',
	'H',
	'H',
	'I',
	'H',
	'I',
	'H',
	'H',
	'H',
	'H',
	'F',
	'H',
	'H',
	'F',
	'F',
	'H',
	'I',
	'I',
	'H',
	'H',
	'F',
	'H',
	'I',
	'H',
	'F',
	'H',
	'H',
	'F',
	'I',
	'I',
	'H',
	'H',
	'I',
	'H',
	'I',
	'T',
	'H',
	'H',
	'F',
	'H',
	'P',
	'I',
	'H',
	'H',
	'I',
	'I',
	'I',
	'F',
	'I',
	'H',
	'H',
	'G',
	'F',
	'F',
	'H',
	'H',
	'H',
	'H',
	'H',
	'H',
	'F',
	'H',
	'H',
	'H',
	'F',
	'F',
	'F',
	'F',
	'F',
	'H',
	'H',
	'H',
	'I',
	'F',
	'F',
	'F',
	'F',
	'I',
	'H',
	'I',
	'H',
	'H',
	'H',
	'G',
	'H',
	'H',
	'H',
	'H',
	'I',
	'F',
	'H',
	'H',
];

export const names = [
	'cindy',
	'gabrie',
	'maylessa',
	'foxy',
	'farla',
	'brie',
	'darth',
	'royland',
	'ruth',
	'jerica',
	'stacy',
	'gho',
	'kay',
	'yerza',
	'caol',
	'bucky',
	'ginina',
	'ganerle',
	'marlov',
	'gaga',
	'joeana',
	'kelsy',
	'keith',
	'don',
	'tatayana',
	'madilyn',
	'juliana',
	'mary',
	'',
	'titan',
	'jaston',
	'merissa',
	'whilm',
	'groupie',
	'pam',
	'lemathe',
	'worin',
	'jenis',
	'millisia',
	'alexus',
	'montanmy',
	'cary',
	'steave',
	'collie',
	'nochin',
	'amada',
	'tandul',
	'meka',
	'jesse',
	'ambus',
	'haiti',
	'jennica',
	'alis',
	'debra',
	'sharrie',
	'karly',
	'boosha',
	'ploga',
	'katherine',
	'bruse',
	'',
	'debra',
	'methbeth',
	'kendif',
	'kio',
	'evane',
	'evon',
	'alien',
	'maritsa',
	'merissa',
	'mellisa',
	'miritsa',
	'larissa',
	'mayitza',
	'manul',
	'dhola',
	'lole',
	'shaggy',
	'greg',
	'aiden',
	'lazy',
	'pricella',
	'lin',
	'kris-chin',
	'roff',
	'granole',
	'horbael',
	'hobart',
	'homar',
	'omae',
	'sue',
	'jei',
	'beridet',
	'gio',
	'arin',
	'camilla',
	'men',
	'jerame',
	'jhanette',
	'jeren',
	'jerried',
	'jorry',
	'',
	'prijah',
	'priyah',
	'priya',
	'kalire',
	'idus',
	'louise',
	'loius',
	'louies',
	'louis',
	'weilan',
	'jolly',
	'jill',
	'lidnsy',
	'heinl',
	'pollen',
	'meanid',
	'rackel',
	'rocelle',
	'estefoni',
	'darren',
	'cody',
	'highway',
	'brino',
	'casn',
	'ellen',
	'keilo',
	'cage',
	'isa',
	'elissan',
	'holga',
	'sweter',
	'jordg',
	'gina',
	'fullta',
	'sharonica',
	'',
	'enuzie',
	'darcy',
	'parkus',
	'rish',
	'jallet',
	'',
	'jaret',
	'janette',
	'vanet',
	'alexon',
	'rush',
	'akizzen',
	'kinth',
	'themile',
	'jermiel',
	'brokee',
	'perla',
	'violut',
	'evil',
	'recrele',
	'pee',
	'judy',
	'?1',
	'briz',
	'boner',
	'raksen',
	'mufgarith',
	'jen',
	'pinkus',
	'danielle',
	'jooke',
	'math',
	'ad',
	'danno',
	'curyn',
	'elianza',
	'olle',
	'chilelle',
	'',
	'parker',
	'gayna',
	'baltimore',
	'jane',
	'louk',
	'oha',
	'aña',
	'aarad',
	'slitch',
	'emilly',
	'voota',
	'dendt',
	'frota',
	'chlob',
	'lauren',
	'mitchell',
	'metchat',
	'elysia',
	'jet',
	'kahee',
	'blibe',
	'nellis',
	'deja',
	'natilia',
	'phyllis',
	'chelsie',
	'cyndi',
	'shareena',
	'terv',
	'taffy',
	'malene',
	'nabil',
	'noira',
	'bripney',
	'jade',
	'salene',
	'hospie',
	'crason',
	'quess',
	'dingo',
	'michel',
	'jen',
	'shanu',
	'',
	'grena',
	'toshy',
	'ausie',
	'micheal',
	'coop',
	'nikki',
	'korin',
	'millysa',
	'collene',
	'dean',
	'tyler',
	'cristin',
	'tusash',
	'edlir',
	'shunna',
	'caotlyne',
	'',
	'holland',
	'phobee',
	'fee',
	'nur',
	'raja',
	'mali',
	'piag',
	'dami',
	'erick',
	'fiola',
	'lazey',
	'alie',
	'gym',
	'erie',
	'alas',
	'aevarth',
	'joline',
	'jaline',
	'tina',
	'lineth',
	'racheal',
	'blkursty',
	'julie',
	'ken',
	'sum',
	'dasta',
	'kerstin',
	'quine',
	'ketlin',
	'olga',
	'an',
	'gloria',
	'joshpe',
	'drega',
	'casidy',
	'blant',
	'racson',
	'luaren',
	'lily',
	'polly',
	'brendra',
	'baioy',
	'agustine',
	'super',
	'marques',
	'laycee',
	'kad',
	'roi',
	'nicole',
	'warton',
	'charlene',
	'gevonu',
	'merash',
	'rechealle',
	'katelan',
	'grel',
	'virgin',
	'ferensiz',
	'elauiner',
	'fioang',
	'gianna',
	'',
	'matiy',
	'maggie',
	'yunis',
	'lilly',
	'dobi',
	'add',
	'norm',
	'cherice',
	'nate',
	':)',
	'lee',
	'lisa',
	'kyra',
	'joymi',
	'',
	'maritha',
	'britney',
	'kaly',
	'panara',
	'jeuels',
	'subien',
	'krisdna',
	'kyle',
	'ramheal',
	'bernie',
	'mexico',
	'juile',
	'arie',
	'justine',
	'merasha',
	'sillian',
	'allibia',
	'damella',
	'chelsie',
	'galda',
	'katlen',
	'maxil',
	'denese',
	'brett',
	'marrisy',
	'schieller',
	'duviol',
	'maira',
	'tyra',
	'cusey',
	'jordan',
	'naddie',
	'graig',
	'leah',
	'lora',
	'kiff',
	'barrufio',
	'',
	'mayo',
	'kuleo',
	'idel',
	'arial',
	'laurent',
	'airin',
	'michaell',
	'jazmin',
	'beuy',
	'?2',
	'holly',
	'pitea',
	'gary',
	'kathline',
	'auto',
	'collie',
	'joolee',
	'coline',
	'boe',
	'po',
	'hayde',
	'devin',
	'lunie',
	'feiw',
	'kalan',
	'cyndi',
	'capon',
	'dione',
	'any',
	'whendee',
	'fill',
	'gaylynn',
	'krysen',
	'kurth',
	'fible',
	'kindrie',
	'kyle',
	'ain',
	'mamad',
	'hiedi',
	'lint',
	'ilean',
	'judy',
	'debora',
	'danyur',
	'platen',
	'nehn',
	'naija',
	'parson',
	'carsie',
	'narson',
	'carston',
	'anny',
	'reel',
	'sheryl',
	'laynton',
	'erie',
	'kaylo',
	'coul',
	'kaid',
	'at',
	'son',
	'may lei',
	'tranquil',
	'trinke',
	'torri',
	'alj',
	'sandra',
	'adria',
	'jean',
	'randle',
	'aluha',
	'andria',
	'brnie',
	'melo',
	'jackie',
	'derrke',
	'karren',
	'kim',
	'pina',
	'margarette',
	'havan',
	'tylor',
	'kae',
	'carollne',
	'',
	'becon',
	'killy',
	'kathren',
	'zues',
	'lizza',
	'aleixis',
	'samie',
	'sharmena',
	'har',
	'trissa',
	'rihanne',
	'sherry',
	'rutnia',
	'rafio',
	'kaysea',
	'juily',
	'lexy',
	'danial',
	'nas',
	'henna',
	'gerage',
	'nay',
	'lotria',
	'erina',
	'kis',
	'brea',
	'gory',
	'rio',
	'lexus',
	'kel',
	'paehc',
	'charolette',
	'mya',
	'courtenay',
	'batt',
	'kristen',
	're',
	'judis',
	'allen',
	'kathleen',
	'anvthyst',
	'javier',
	'debra',
	'lien',
	'kale',
	'harii',
	'sharlotte',
	'alician',
	'elkry',
	'',
	'stephanie',
	'alen',
	'dirian',
	'lettuces',
	'cloey',
	'vasak',
	'mourine',
	'eing',
	'juanneena',
	'melissa',
	'jolina',
	'samartha',
	'jane',
	'hopal',
	'creige',
	'ailisig',
	'lexin',
	'harvey',
	'feny',
	'nesta',
	'allies',
	'leana',
	'broatey',
	'stewen',
	'dane',
	'coley',
	'reese',
	'beyanka',
	'laura',
	'skayler',
	'meggie',
	'peter',
	'elena',
	'lee',
	'pole',
	'isla',
	'smeilene',
	'keny',
	'mayhuh',
	'lorraina',
	'duck',
	'gioven',
	'harriet',
	'brick',
	'kumtar',
	'encear',
	'portiric',
	'tefamy',
	'raymond',
	'charlie',
	'holena',
	'brabnello',
	'christin',
	'mychell',
	'lorry',
	'lorhen',
	'hai',
	'lorrie',
	'lory',
	'lora',
	'ingris',
	'michael',
	't',
	'naun',
	'barlen',
	'trishian',
	'catrine',
	'emily',
	'olive',
	'lory',
	'estefani',
	'aeanne',
	'larrisa',
	'leuian',
	'maranne',
	'rem',
	'lara',
	'clarif',
	'damon',
	'zohi',
	'jeph',
	'packy',
	'rebeka',
	'ton',
	'tim',
	'daniel',
	'kirim',
	'rotci',
	'jepui',
	'zui',
	'whell',
	'chelsea',
	'herald',
	'clif',
	'iolin',
	'doo',
	'syntheia',
	'chake',
	'cathine',
	'robert',
	'',
	'',
	'football',
	'ciriria',
	'matra',
	'sabata',
	'no',
	'wailml',
	'',
	'shalet',
	'alice',
	'gilan',
	'ten',
	'chelbie',
	'zimond',
	'kelly',
	'cynthia',
	'denisse',
	'alian',
	'lorie',
	'maurice',
	'bat',
	'hutt',
	'chamber',
	'?3',
	'paul',
	'brianne',
	'kaila',
	'steve',
	'luz',
	'davis',
	'bita',
	'winday',
	'steph]',
	'gabby',
	'death',
	'metchal',
	'shaggy',
	'evie',
	'courtenay',
	'alis',
	'lynonn',
	'deauh',
	'l',
	'leanna',
	'adi',
	'lindsay',
	'france',
	'silwia',
	'kitty',
	'',
	'rabin',
	'uon',
	'heliy',
	'rebign',
	'jod',
	'gud',
	'mary',
	'nynia',
	'maggy',
	'sophaya',
	'iailt',
	'radell',
	'batman',
	'dennie',
	'reipar',
	'adem',
	'star',
	'breona',
	'traviz',
	'bionka',
	'macke',
	'rolling',
	'brignette',
	'ezabell',
	'rowmen',
	'robicca',
	'dian',
	'shat',
	'bod',
	'din',
	'carrie',
	'carry',
	'caitlin',
	'trasy',
	'wong',
	'booja',
	'titty',
	'nemo',
	'imogem',
	'kotry',
	'arar',
	'?4',
	'caroon',
	'carsey',
	'jen',
	'catmrine',
	'lurel or lourvel or lorel',
	'fibi',
	'linett',
	'divn',
	'ricon',
	'bror',
	'jene',
	'jain',
	'pihti',
	'ghana ',
	'neo',
	'esthefi',
	'ribay',
	'lory',
	'magen',
	'arika',
	'saull',
	'marn',
	'winona',
	'ram',
	'reackel',
	'kimbrly',
	'weell',
	'edwin',
	'lesi',
	'?5',
	'aleey',
	'amely',
	'mareen',
	'maconda',
	'kathline',
	'golden',
	'candles',
	'seyia',
	'ralsea',
	'sardin',
	'boneem',
	'rhiana',
	'aaron',
	'cheo',
	'drue',
	'kaik',
	'donna',
	'khaley',
	'urserlla',
	'chann',
	'muranda',
	'daisy',
	'haten',
	'hkolleane',
	'cinthea',
	'bruk',
	'machille',
	'lizu',
	'einesha',
	'valiry',
	'meg',
	'kaylob',
	'estbelk',
	'kelvin',
	'scoth',
	'erine',
	'cean',
	'aren',
	'rebel',
	'kaitlann',
	'kate',
	'jimmy',
	'rebekha',
	'gean',
	'margie',
	'chetta',
	'hundres',
	'alexison',
	'johnnie',
	'june',
	'alliyah',
	'entoiad',
	'lora',
	'aleson',
	'ashe',
	'styles',
	'favor',
	'alicia',
	'jone',
	'evan',
	'kitten',
	'aaron',
	'blass',
	'pena',
	'anna',
	'yesua',
	'katlyn',
	'penai',
	'eric',
	'corrola',
	'missle',
	'kayla',
	'mac',
	'iris',
	'elyca',
	'shillby',
	'flow',
	'lei',
	'vibi',
	'vebee',
	'kiera',
	'lydsney',
	'babela',
	'alax',
	'manly',
	'jean',
	'mess',
	'polo',
	'gloria',
	'sab',
	'haryt',
	'perry',
	'heriett',
	'picksie',
	'steaven',
	'voldamort',
	'nan',
	'mikenski',
	'petti',
	'dalphy',
	'mkcenzie',
	'dia',
	'thei',
	'rubiap',
	'gilu',
	'patrisha',
	'kardine',
	'kane',
	'diane',
	'jema',
	'mad-ah-lynn',
	'pigo',
	'sailor',
	'ibellium',
	'gib',
	'tuckilya',
	'spanser',
	'karmin',
	'allen',
	'aleashia',
	'lya',
	'mykala',
	'lylos',
	'gaherlott',
	'mila',
	'liyi',
	'lailo',
	'cheft',
	'berra',
	'litzy',
	'easy',
	'vocsan',
	'fimbpy',
	'chole',
	'prik',
	'blan',
	'wlio',
	'sarry',
	'dillian',
	'pony',
	'jone',
	'ive',
	'boris',
	'jail',
	'aby',
	'heidi',
	'barry',
	'giggles',
	'darlul',
	'amanda',
	'besty',
	'fili',
	'ileen',
	'gasca',
	'tiphony',
	'martheful',
	'laria',
	'chelsea',
	'a-me',
	'player',
	'giny',
	'verginya',
	'molten',
	'mavdat',
	'kristil',
	'rebecka',
	'cherry',
	'harold',
	'hazul',
	'cyedny',
	'ezibella',
	'quartney',
	'raghleigh',
	'berry',
	'poiest',
	'chelsay',
	'cats',
	'careen',
	'karson',
	'carson',
	'carsud',
	'buddy',
	'cendy',
	'shubh',
	'gurry',
	'sharlet',
	'diki',
	'berk',
	'leften',
	'ridge',
	'dayan',
	'apizy',
	'aubrey',
	'tanner',
	'gia',
	'earn',
	'almond',
	'sonla',
	'garey',
	'glorin',
	'parker',
	'wang',
	'amnika',
	'fornetta',
	'o',
	'kollen',
	'vanilia',
	'aliz',
	'downy',
	'lid',
	'emmie',
	'dash',
	'elisa',
	'eric',
	'',
	'imie',
	'ejay',
	'wayne',
	'cheeshy',
	'alican',
	'dairius',
	'azath',
	'jeff',
	'molley',
	'bristiba',
	'bobro',
	'ari inn',
	'auch',
	'adrain',
	'collin',
	'baniel',
	'germine',
	'nittley',
	'tac',
	'carline',
	'ashleey freed',
	'jatin',
	'jeff',
	'prornwin',
	'sewd',
	'sonla',
	'any',
	'marice',
	'carolyn',
	'reclier',
	'olorn',
	'moron',
	'rabi',
	'helen',
	'phobia',
	'daze',
	'',
	'jusel',
	'gaiea',
	'joyse',
	'elaink',
	'zoey',
	'keith',
	'partha',
	'nali',
	'jazimin',
	'spina',
	'jacell',
	'pebba',
	'loen',
	'tesale',
	'danine',
	'gaillon',
	'katheran',
	'honey connie',
	'prina',
	'megan',
	'briayan',
	'moti',
	'santa',
	'meloney',
	'eursela',
	'carfy',
	'?6',
	'louisee',
	'riber',
	'maress',
	'',
	'debbie',
	'belutia',
	'corolrian',
	'primrose everdeen',
	'presly',
	'passion',
	'heather',
	'samen',
	'yelena',
	'paritsa',
	'pastry',
	'lery',
	'penellipie',
	'lidia',
	'corel',
	'syarah',
	'kateland',
	'rocsi',
	'perisa',
	'baricia',
	'daninel',
	'blad',
	'rod',
	'vadler',
	'creag',
	'erich',
	'marbra',
	'libral',
	'gazelle',
	'gab',
	'sahara',
	'cardinal',
	'mog',
	'mudule',
	'jaramie',
	'leoues',
	'',
	'alex',
	'deny',
	'broke',
	'sanje',
	'tatna',
	'lousie',
	'ebbie',
	'donie',
	'grof',
	'spirow',
	'trug',
	'alien',
	'rola',
	'tary',
	'evan',
	'en',
	'jen',
	'sasealya',
	'nick',
	'rat',
	'botchada',
	'livra',
	'tez',
	'crustle',
	'dunal',
	'lorry',
	'',
	'seara',
	'seara',
	'ecky',
	'lynn',
	'vergona',
	'ketty',
	'sherril',
	'ailson',
	'jd',
	'brain',
	'meow',
	'mogone',
	'janiver',
	'stayc',
	'parlcu',
	'heleoren',
	'apron',
	'haypu',
	'vawl',
	'pony',
	'jasitan',
	'shirry',
	'pallapi',
	'gale',
	'mat',
	'bunksha',
	'christen',
	'polly',
	'chrisben',
	'ilisia',
	'fam',
	'hartsan',
	'carsan',
	'wid',
	'steey',
	'carsey',
	'cale',
	'ciady',
	'sherry',
	'metchul',
	'borin',
	'angie',
	'janna',
	'lyzeth',
	'madellin',
	'ralph',
	'eriall',
	'joed',
	'syreena',
	'alice',
	'dreyo',
	'nichol',
	'justine',
	'renarldo',
	'lilyen',
	'carlee',
	'',
	'leia',
	'han',
	'lanea',
	'ales',
	'kris',
	'brigittta',
	'natasha',
	'malon',
	'riana',
	'eric',
	'corrie',
	'lindsey lohan',
	'its renee',
	'kathlein',
	'rinaldo',
	'natie',
	'megan',
	'angler',
	'',
	'brigitta',
	'tool',
	'litia',
	'hellen',
	'anie',
	'joan',
	'gabby',
	'dave',
	'pollen',
	'chancuer',
	'statlley',
	'jesice',
	'sjiann',
	'johin',
	'pula',
	'xena',
	'danyele',
	'aruse',
	'rebecca',
	'mitus',
	'',
	'jaques',
	'trich',
	'mullen',
	'carlene',
	'christen',
	'debbie',
	'mia',
	'shontel',
	'bakkie',
	'cristmian',
	'anger',
	'shopra',
	'olbama',
	'niclla',
	'coby',
	'eeron',
	'renold',
	'lisa',
	'l.e.',
	'anecia',
	'spuscun',
	'emelly',
	'ryss',
	'brian',
	'magan',
	'isla',
	'correy',
	'rute',
	'surly',
	'mary',
	'zui',
	'nia',
	'leennn',
	'froncine',
	'chatal',
	'diego',
	'sales',
	'mierla',
	'alers',
	'sherlun',
	'stia',
	'cathrine',
	'e',
	'keiti',
	'may',
	'evelin',
	'quib',
	'cloie',
	'haceldie',
	'shavan',
	'shaniqua',
	'auntie',
	'adry',
	'pam',
	'venisha',
	'falestha',
	'felcira',
	'alishia',
	'philesha',
	'?7',
	'fillionna',
	'jilly',
	'maian',
	'amely',
	'parris',
	'christol',
	'shrille',
	'wrreck',
	'gosh',
	'gris',
	'malo',
	'suazei',
	'gylan',
	'aaron',
	'pork',
	'kay',
	'katty',
	'jami',
	'ashton',
	'ellen',
	'gretta',
	'casid-e',
	'chippy',
	'mad',
	'awhina',
	'',
	'broke',
	'genus',
	'ashley',
	'cherry ann',
	'cionet',
	'gena',
	'janie',
	'jill',
	'jordan',
	'naija',
	'reese',
	'sharlet',
	'steff',
	'susy',
	'treresa',
	'tyler',
	'hiati',
	'alivia',
	'anatira',
	'azarat',
	'bodd',
	'candance',
	'catiran',
	'chras',
	'clowy',
	'colon',
	'curli',
	'danilla',
	'denis',
	'eantha',
	'easy',
	'eber',
	'eder',
	'emsi',
	'feline',
	'folen',
	'garim',
	'griely',
	'haphme',
	'hater',
	'iney',
	'jannett',
	'kenya',
	'keydi',
	'lebi',
	'lenuy',
	'liao',
	'lisdsey',
	'lola',
	'lonye',
	'mara',
	'marrick',
	'wye',
	'murray',
	'myllysa',
	'pensa',
	'phillidia',
	'pit',
	'que',
	'semen',
	'shear',
	'tennis',
	'wallup',
	'withney',
];
export const nonAtoZchoices : number[] = names.map((name, index)=>name[0] === "?" ? index : -1).filter((val)=>val > -1);

export const translations: {
	[lang: string]: {
		logo: string;
		different: string;
		notWhat: string;
		name: string;
		for: string;
		W: string[];
		A: string[];
		T: string[];
		R: string[];
		S: string[];
		L: string[];
		E: string[];
		O: string[];
		P: string[];
		G: string[];
		F: string[];
		H: string[];
		I: string[];
	};
} = {
	pt: {
		logo: 'logo-pt.png',
		different: 'Tente um Starbucks diferente.',
		notWhat: 'Isso não é o que eu pedi.',
		name: 'Posso obter um nome para o seu fim?',
		for: 'para',
		I: [
			'Café do dia Gelado',
			'Espresso Macchiato Gelado',
			'Café com Leite Gelado',
			'Café Americano Gelado',
			'Cajeta Latte Gelado',
			'Café Mocha Blanco Gelado',
			'Café Latte Gelado',
			'Baunilha Latte Gelado',
			'Doce de leite Latte Gelado',
			'Caramelo Macchiato Gelado',
			'Café Mocha Gelado',
		],
		H: [
			'Prensa Francesa',
			'Café do dia',
			'Café Espresso',
			'Espresso Macchiato',
			'Café com Leite',
			'Café Americano',
			'Cajeta Latte',
			'Café Mocha Blanco',
			'Café Latte',
			'Cappuccino',
			'Cappuccino Chocolate',
			'Cappuccino Canela',
			'Baunilha Latte',
			'Doce de leite Latte',
			'Caramelo Macchiato',
			'Café Mocha',
			'Café Mocha Branco',
		],
		P: ['Refreshers Frutas Vermelhas'],
		G: ['Chá Verde Gelado'],
		F: [
			'Doce de leite Frappuccino',
			'Choco Chip Frappuccino',
			'Mocha Frappuccino',
			'Mocha Branco Frappuccino',
			'Café Frappuccino',
			'Caramelo Frappuccino',
		],
		W: ['Café Mocha Branco Gelado'],
		A: ['Água'],
		T: ['Chá Gelado com Limonada', 'Chá Gelado'],
		R: ['Chá verde Frappuccino'],
		S: ['Morango Frappuccino'],
		L: ['Refreshers Limão'],
		E: ['Chá Verde Latte'],
		O: ['Manga Frappuccino'],
	},
	fr: {
		logo: 'logo-fr.png',
		different: 'Essayez un autre Starbucks.',
		notWhat: 'Ce ne est pas ce que je ai commandé.',
		name: 'Puis-je obtenir un nom pour votre commande?',
		for: 'pour',
		I: [
			'Caffè Americano glacé',
			'Espresso Macchiato glacé',
			'Café de la semaine glacé',
			'Caramel Macchiato glacé',
			'Mocha Blanc glacé',
			'Caffè Latte glacé',
			'Chai Tea Latte glacé',
			'Caffè Mocha glacé',
			'Ristretto Bianco glacé',
		],
		H: [
			'Caffè Americano',
			'Espresso Macchiato',
			'Cappuccino',
			'Café de la semaine',
			'Caramel Macchiato',
			'Espresso Con Panna',
			'Mocha Blanc',
			'Caffè Latte',
			'Chai Tea Latte',
			'Chocolat Viennois',
			'Caffè Mocha',
			'Ristretto Bianco',
		],
		P: ["Mûre aromatisée à l'hibiscus"],
		G: ['Green Tea glacé'],
		F: [
			'Caramel Frappuccino',
			'Espresso Frappuccino',
			'Java Chip Frappuccino',
			'Mocha Frappuccino',
			'Café Frappuccino',
		],
		W: ['Mocha Blanc Mocha glacé'],
		A: ['Eau'],
		T: [
			'Infusion Camomille glacé',
			'Thé English Breakfast glacé',
			'Chai Tea glacé',
			'Thé glacé',
		],
		R: ['Green Tea Crème Frappuccino'],
		S: ['Fraise à la crème Soja Frappuccino'],
		L: ['Citron Vert'],
		E: ['Green Tea Latte'],
		O: ['Mangue Passion Frappuccino'],
	},
	es: {
		logo: 'logo-es.png',
		different: 'Pruebe con un Starbucks diferente.',
		notWhat: 'Eso no es lo que pedí.',
		name: '¿Puedo obtener un nombre para su pedido?',
		for: 'para',
		I: [
			'Helado Caramel Macchiato',
			'Helado Espresso Americano',
			'Helado Vainilla Latte',
			'Café Mocha Blanco',
			'Helado Café Latte',
			'Helado Mocha Latte',
			'Helado Café de Día',
		],
		H: [
			'Espresso Americano',
			'Espresso Macchiato',
			'Cappuccino',
			'Café de Día',
			'Caramel Macchiato',
			'Marble Mocha Macchiato',
			'Cajeta Latte',
			'Café Mocha Blanco',
			'Latte',
			'Vanilla Latte',
			'Té Chai Latte',
			'Chocolate Caliente',
			'Café Mocha',
		],
		P: ['Berry Refresher'],
		G: ['Helado Green Tea'],
		F: [
			'Caramel Frappuccino',
			'Chip Frappuccino',
			'Mocha Frappuccino',
			'Mocha Cookie Frappuccino',
			'Café Frappuccino',
			'Cajeta Frappuccino',
		],
		W: ['Helado Café Mocha Blanco'],
		A: ['Agua'],
		T: [
			'Helado Chamomile',
			'Helado English Breakfast',
			'Helado Té Chai',
			'Helado Té',
			'Helado Vanilla Rooibos',
		],
		R: ['Green Tea Frappuccino'],
		S: ['Fresa Cream Frappuccino'],
		L: ['Lemon Refresher'],
		E: ['Green Tea Latte'],
		O: ['Batido De Mango'],
	},
	en: {
		logo: 'logo.png',
		different: 'Try A Different Starbucks.',
		notWhat: "That's not what I ordered.",
		name: 'Can I get a name for your order?',
		for: 'for',
		I: [
			'Caramel Iced Coffee',
			'Iced Americano',
			'Vanilla Iced Coffee',
			'Iced White Chocolate Mocha',
			'Iced Caramel Macchiato',
			'Iced Latte',
			'Iced Vanilla Latte',
			'Doubleshot on Ice',
			'Iced Mocha Latte',
			'Iced Coffee',
		],
		H: [
			'Americano',
			'Espresso Macchiato',
			'Cappuccino',
			'Blonde Roast',
			'Dark Roast',
			'Medium Roast',
			'Caramel Macchiato',
			'Cinnamon Dolce Latte',
			'Skinny Vanilla Latte',
			'White Chocolate Mocha',
			'Latte',
			'Vanilla Latte',
			'Chai Tea Latte',
			'Hot Chocolate',
			'Mocha Latte',
			'Flat White',
			'Pike Place Roast',
		],
		P: ['Very Berry Hibiscus Refresher'],
		G: ['Iced Green Tea'],
		F: [
			'Caramel Frappuccino',
			'Vanilla Bean Crème Frappuccino',
			'Java Chip Frappuccino',
			'Mocha Frappuccino',
			'Double Chocolaty Chip Crème Frappuccino',
			'Coffee Frappuccino',
			'Cinnamon Dolce Frappuccino',
		],
		W: ['Iced White Chocolate Mocha'],
		A: ['Water'],
		T: [
			'Iced Chamomile',
			'Iced English Breakfast Tea',
			'Iced Chai Tea',
			'Iced Tea',
		],
		R: ['Green Tea Crème Frappuccino'],
		S: ['Strawberries & Crème Frappuccino'],
		L: ['Cool Lime Refresher'],
		E: ['Green Tea Latte'],
		O: ['Orange Mango Smoothie'],
	},
	de: {
		logo: 'logo-de.png',
		different: 'Versuchen Sie eine Starbucks.',
		notWhat: 'Das ist nicht, was ich bestellte.',
		name: 'Kann ich einen Namen für Ihre Bestellung erhalten?',
		for: 'für',
		I: [
			'Iced Americano',
			'Iced Espresso Macchiato',
			'Iced Filterkaffee',
			'Iced Caramel Macchiato',
			'Iced Latte',
			'Iced Chai Tea Latte',
			'Iced Caramel Hot Chocolate',
			'Iced Chocolate Mocha',
			'Iced Flat White',
		],
		H: [
			'Americano',
			'Espresso Macchiato',
			'Cappuccino',
			'Filterkaffee',
			'Caramel Macchiato',
			'Latte',
			'Chai Tea Latte',
			'Caramel Hot Chocolate',
			'Chocolate Mocha',
			'Flat White',
		],
		P: ['Very Berry Hibiscus'],
		G: ['Iced Green Tea'],
		F: [
			'Caramel Frappuccino',
			'Espresso Frappuccino',
			'Java Chip Frappuccino',
			'Mocha Frappuccino',
			'Coffee Frappuccino',
		],
		W: ['Iced White Chocolate Mocha'],
		A: ['Wasser'],
		T: [
			'Iced Chamomile Tea',
			'Iced English Breakfast Tea',
			'Iced Chai Tea',
			'Iced Tea',
		],
		R: ['Green Tea Frappuccino'],
		S: ['Strawberries & Cream Frappuccino'],
		L: ['Cool Lime'],
		E: ['Green Tea Latte'],
		O: ['Mango Passionsfrucht Tea Frappuccino'],
	},
};
